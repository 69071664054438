import queryString from "query-string"

const resizeImage = (image: any): {
  height: number,
  src: string,
  width: number
} => {
  const data = {
    height: image?.height ?? 0,
    src: image?.images?.fallback?.src ?? ``,
    width: image?.width ?? 0,
  }

  if(data.src === ``) return data
  
  // Resize image to max 1200w
  const queries = queryString.parse(data.src.split(`?`)[1]),
    ratio = data.height / data.width ?? 1

  data.width = Math.min(data.width, 1200)
  data.height = Math.round(ratio * data.width)
  queries.w = data.width
  queries.h = data.height
  data.src = `${data.src.split(`?`)[0]}?${queryString.stringify(queries)}`

  return data
}

export {
  resizeImage
}